import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MdClose, MdInfo, MdError, MdCheckCircle } from 'react-icons/md';

import IconButton from 'components/IconButton';
import { useToast } from 'contexts/toast';

import * as S from './styles';

const icons = {
  info: <MdInfo />,
  error: <MdError />,
  success: <MdCheckCircle />,
};

function Toast({ message }) {
  const { removeMessage } = useToast();

  useEffect(() => {
    const timer = setTimeout(() => {
      removeMessage(message.id);
    }, 10000);

    return () => {
      clearTimeout(timer);
    };
  }, [message.id, removeMessage]);

  return (
    <S.Wrapper type={message.type} role="alert">
      {icons[message.type || 'info']}

      <S.Content>
        <S.Title>{message.title}</S.Title>
      </S.Content>

      <IconButton color="white" onClick={() => removeMessage(message.id)}>
        <MdClose />
      </IconButton>
    </S.Wrapper>
  );
}

Toast.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string,
  }).isRequired,
};

export default Toast;
