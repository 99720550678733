import create from 'zustand';

export const useErrorStore = create((set) => {
  return {
    messages: [],
    shouldRedirect: false,
    add: (message, redirect = false) => {
      return set((state) => {
        return { messages: [...state.error.messages, message], redirect };
      });
    },
    addAll: (messages, redirect = false) => {
      return set((state) => ({ messages: [...state.error.messages, ...messages], redirect }));
    },
    removeAll: () => set({ messages: [], shouldRedirect: false }),
  };
});
