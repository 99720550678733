import React from 'react';

import * as S from './styles';

function FullPageSpinner() {
  return (
    <S.Wrapper>
      <S.Spinner />
    </S.Wrapper>
  );
}

export default FullPageSpinner;
