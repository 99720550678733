const isTest = process.env.NODE_ENV === 'test';

const config = {
  debug: false,
  defaultNS: ['translations'],
  fallbackLng: ['pt-BR', 'en-US'],
  ns: ['translations'],
  lng: isTest && 'pt-BR',
};

export default config;
