import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client';

import mixpanel from 'mixpanel-browser';
import { MixpanelProvider } from 'react-mixpanel';

import { AuthProvider } from 'contexts/auth';
import { ToastProvider } from 'contexts/toast';
import ErrorBoundary from 'components/Error/ErrorBoundary';
import ErrorModalRoot from 'components/ErrorModalRoot';
import sentryConfig from 'configs/sentry';
import Routes from 'routes';
import GlobalStyle from 'styles/global';
import theme from 'styles/theme';
import client from 'graphql/client';

import 'locales/i18n';
import 'bootstrap/dist/css/bootstrap.min.css';

Sentry.init(sentryConfig);
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || '');
const mixpanelInit = { init: () => {}, people: {}, track: () => {}, register: () => {} };

const App = () => (
  <ApolloProvider client={client}>
    <MixpanelProvider mixpanel={process.env.REACT_APP_MIXPANEL_TOKEN ? mixpanel : mixpanelInit}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <BrowserRouter>
            <GlobalStyle />
            <ErrorBoundary>
              <ErrorModalRoot />
              <ToastProvider>
                <Routes />
              </ToastProvider>
            </ErrorBoundary>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </MixpanelProvider>
  </ApolloProvider>
);

export default App;
