import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import Dino from 'components/Dino';
import i18n from 'locales/i18n';

import * as S from './styles';

class ErrorBoundary extends PureComponent {
  state = {
    hasError: false,
  };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (!hasError) {
      return children;
    }

    return (
      <S.Wrapper>
        <S.DinoWrapper>
          <Dino />
        </S.DinoWrapper>

        <S.Content>
          <S.Text>
            {i18n.t('opsSomethingWrong')}
            <br />
            {i18n.t('criticalErrorMessagePart1')} <b>{i18n.t('app')}</b> {i18n.t('criticalErrorMessagePart2')}
          </S.Text>
          <S.Link href="/">{i18n.t('goToHomePage')}</S.Link>
        </S.Content>
      </S.Wrapper>
    );
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
