import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

function Button({ children, color, font, fullWidth, size, text, variant, ...props }) {
  return (
    <S.Wrapper color={color} size={size} font={font} fullWidth={fullWidth} text={text} variant={variant} {...props}>
      {children}
    </S.Wrapper>
  );
}

Button.defaultProps = {
  color: 'primary',
  font: 'primary',
  fullWidth: false,
  size: 'medium',
  text: 'upper',
  variant: 'solid',
};

Button.propTypes = {
  as: PropTypes.elementType,
  color: PropTypes.oneOf(['primary', 'secondary', 'error', 'white', 'default']),
  font: PropTypes.oneOf(['primary', 'secondary']),
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  text: PropTypes.oneOf(['upper', 'normal']),
  variant: PropTypes.oneOf(['solid', 'outline']),
};

export default Button;
