import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme, isOpen }) => css`
    position: fixed;
    right: 0;
    top: 0;
    padding: ${theme.spacings.medium};
    overflow: hidden;
    z-index: ${theme.layers.alwaysOnTop};

    ${isOpen &&
    css`
      opacity: 1;
      pointer-events: all;
    `}

    ${!isOpen &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
  `}
`;
