import styled, { css } from 'styled-components';

const wrapperModifiers = {
  primary: {
    solid: (theme) => css`
      background: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:hover {
        background: ${theme.colors.darkPrimary};
        color: ${theme.colors.white};
      }
    `,
    ghost: (theme) => css`
      color: ${theme.colors.primary};

      &:hover,
      &:focus {
        color: ${theme.colors.darkPrimary};
      }
    `,
  },
  secondary: {
    solid: (theme) => css`
      background: ${theme.colors.secondary};
    `,
    ghost: (theme) => css`
      color: ${theme.colors.secondary};

      &:hover,
      &:focus {
        color: ${theme.colors.darkSecondary};
      }
    `,
  },
  gray: {
    solid: (theme) =>
      css`
        background: ${theme.colors.gray};
      `,
    ghost: (theme) => css`
      color: ${theme.colors.gray};

      &:hover,
      &:focus {
        color: ${theme.colors.gray};
        opacity: 0.7;
      }
    `,
  },
  error: {
    solid: (theme) =>
      css`
        background: ${theme.colors.error};
        color: ${theme.colors.white};

        &:hover,
        &:focus {
          background: ${theme.colors.error};
          color: ${theme.colors.white};
          opacity: 0.7;
        }
      `,
    ghost: (theme) => css`
      color: ${theme.colors.error};

      &:hover,
      &:focus {
        color: ${theme.colors.error};
        opacity: 0.7;
      }
    `,
  },
  white: {
    solid: (theme) =>
      css`
        background: ${theme.colors.white};
      `,
    ghost: (theme) => css`
      color: ${theme.colors.white};

      &:hover,
      &:focus {
        color: ${theme.colors.white};
        opacity: 0.7;
      }
    `,
  },
  black: {
    solid: (theme) =>
      css`
        background: ${theme.colors.black};
      `,
    ghost: (theme) => css`
      color: ${theme.colors.black};

      &:hover,
      &:focus {
        color: ${theme.colors.black};
        opacity: 0.7;
      }
    `,
  },
  success: {
    solid: (theme) =>
      css`
        background: ${theme.colors.success};
        color: ${theme.colors.white};

        &:hover,
        &:focus {
          background: ${theme.colors.success};
          color: ${theme.colors.white};
          opacity: 0.7;
        }
      `,
    ghost: (theme) => css`
      color: ${theme.colors.success};

      &:hover,
      &:focus {
        color: ${theme.colors.success};
        opacity: 0.7;
      }
    `,
  },
  small: () => css`
    > svg {
      height: 18px;
      width: 18px;
    }
  `,
  medium: () => css`
    > svg {
      height: 24px;
      width: 24px;
    }
  `,
  large: () => css`
    > svg {
      height: 40px;
      width: 40px;
    }
  `,
  radius: {
    none: () => css`
      border-radius: 0;
    `,
    small: () => css`
      border-radius: 8px;
    `,
    medium: () => css`
      border-radius: 12px;
    `,
    large: () => css`
      border-radius: 16px;
    `,
    full: () => css`
      border-radius: 50%;
    `,
  },
};

export const Wrapper = styled.button`
  ${({ theme, radius, color, size, variant }) => css`
    align-items: center;
    background-color: transparent;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    padding: ${theme.spacings.xxxsmall};
    transition: ${theme.transitions.duration.short};

    &:focus {
      box-shadow: 0 0 0 2px ${theme.colors.darkPrimary};
      outline: none;
    }

    ${!!color && wrapperModifiers[color][variant](theme)}
    ${!!size && wrapperModifiers[size](theme)}
    ${!!radius && wrapperModifiers.radius[radius]}
  `}
`;
