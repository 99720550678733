import React from 'react';

import * as S from './styles';

function Dino() {
  return (
    <S.Wrapper>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-label="Dino">
        <path
          className="primary"
          d="M353.566 374.034c-5.902 0-11.411-3.715-13.417-9.614l-7.373-21.678c-2.52-7.411 1.446-15.461 8.857-17.981 7.408-2.518 15.462 1.445 17.981 8.857l7.372 21.676c2.521 7.411-1.444 15.461-8.855 17.981a14.144 14.144 0 01-4.565.759z"
        />
        <path
          className="primary"
          d="M264.691 379.633c-62.67 21.141-134.792 45.47-198.962 29.572-10.981-2.72-10.455-18.415.649-20.58 43.695-8.523 75.225-31.013 89.436-55.01l108.877 46.018z"
        />
        <path
          className="primary"
          d="M215.168 464.528c-.562 0-1.126-.021-1.695-.065-12.007-.923-20.992-11.405-20.068-23.412.838-10.896-.06-47.238-1.624-55.286-3.589-10.804 1.777-22.687 12.473-27.045 11.155-4.543 23.877.814 28.421 11.965 5.879 14.43 4.48 70.22 4.211 73.71-.881 11.437-10.436 20.132-21.718 20.133zm-22.89-77.414l.01.025-.01-.025zM294.247 464.528c-.562 0-1.126-.021-1.695-.065-12.007-.923-20.992-11.405-20.068-23.412.838-10.896-.061-47.238-1.624-55.285-3.589-10.804 1.777-22.687 12.473-27.046 11.153-4.545 23.877.813 28.421 11.965 5.88 14.43 4.48 70.22 4.211 73.71-.881 11.437-10.436 20.132-21.718 20.133zm-22.89-77.414l.01.025-.01-.025z"
        />
        <path
          d="M316.301 424.187c-.018-18.534-.817-44.346-4.548-53.504-4.543-11.152-17.268-16.51-28.421-11.965-10.696 4.358-16.061 16.242-12.473 27.046 1.254 6.453 2.079 31.09 1.925 46.221 16.834-1.105 31.258-3.678 43.517-7.798zM204.253 358.719c-10.696 4.358-16.061 16.241-12.473 27.045 1.093 5.625 1.86 25.063 1.937 39.982 12.371 3.578 26.791 5.723 43.464 6.517.202-18.121-.192-50.99-4.507-61.58-4.544-11.15-17.266-16.507-28.421-11.964z"
          opacity=".1"
        />
        <path
          d="M155.814 333.614c-5.217 8.809-12.776 17.412-22.406 25.199 2.46 14.902 7.557 29.14 18.027 41.391a71.749 71.749 0 009.307 9.061c35.576-6.576 71.063-18.538 103.948-29.631l-108.876-46.02z"
          opacity=".1"
        />
        <path
          className="primary"
          d="M352.075 323.079c0 50.904-6.914 87.738-99.551 87.738-92.636 0-99.551-36.834-99.551-87.738s44.57-92.171 99.551-92.171 99.551 41.266 99.551 92.171z"
        />
        <path
          d="M352.075 323.079c0-.406-.011-.809-.017-1.213-20.735-5.321-61.863-6.869-90.483 23.547-20.856 22.165-23.874 46.083-21.637 65.158 4.009.163 8.201.246 12.586.246 92.637 0 99.551-36.834 99.551-87.738z"
          className="active-path secondary"
        />
        <path
          d="M233.626 232.574c-45.941 8.176-80.652 45.584-80.652 90.504 0 47.135 5.94 82.201 80.361 87.137-41.321-41.94-33.329-128.537.291-177.641z"
          opacity=".05"
        />
        <path
          className="primary"
          d="M343.902 98.164a91.812 91.812 0 00-76.582-41.17h-37.885c-53.861 0-97.524 43.663-97.524 97.524v44.838c0 48.34 39.187 87.527 87.527 87.527h200.826c36.212 0 65.568-29.356 65.568-65.568v-30.516c0-36.212-29.356-65.568-65.568-65.568h-26.016a60.36 60.36 0 01-50.346-27.067z"
        />
        <g opacity=".1">
          <circle cx="184.89" cy="220.67" r="6.712" />
          <circle cx="232.6" cy="230.91" r="6.712" />
          <circle cx="205.3" cy="253.8" r="6.712" />
        </g>
        <circle cx="385.9" cy="159.48" r="6.712" fill="#495059" />
        <circle cx="428.67" cy="159.48" r="6.712" fill="#495059" />
        <path
          d="M207.77 59.414c-43.433 9.853-75.858 48.69-75.858 95.105v44.838c0 48.34 39.187 87.527 87.527 87.527h53.111c-77.35-45.006-90.46-168.716-64.78-227.47z"
          opacity=".05"
        />
        <path
          className="primary"
          d="M242.952 374.641c-7.827 0-14.173-6.346-14.173-14.173V337.57c0-7.828 6.346-14.173 14.173-14.173s14.173 6.345 14.173 14.173v22.897c0 7.828-6.346 14.174-14.173 14.174z"
        />
        <path
          fill="#a3e3ff"
          d="M67.665 192.953c-3.205 15.555-16.977 27.25-33.481 27.25C15.305 220.203 0 204.898 0 186.019 0 38.855 216.553 1.655 223.86 109.711a18.623 18.623 0 00-14.182-.162c-48.85-50.135-122.752-26.23-142.013 83.404zM279.894 109.711a18.621 18.621 0 0114.181-.162c36.296-37.251 86.417-33.612 117.684 15.681h8.504c36.212 0 65.568 29.356 65.568 65.568v25.295c10.673-5.784 17.922-17.082 17.922-30.075.001-147.163-216.551-184.363-223.859-76.307z"
        />
        <g>
          <path
            fill="#495059"
            d="M229.766 121.202c-1.92 0-3.84-.732-5.305-2.198a11.11 11.11 0 00-7.909-3.277 11.11 11.11 0 00-7.909 3.277 7.5 7.5 0 11-10.609-10.605c4.946-4.948 11.522-7.673 18.519-7.673 6.996 0 13.573 2.725 18.518 7.673a7.5 7.5 0 01-.003 10.606 7.472 7.472 0 01-5.302 2.197zM300.415 121.202c-1.92 0-3.84-.732-5.305-2.198-2.112-2.113-4.921-3.277-7.909-3.277s-5.797 1.164-7.909 3.277a7.5 7.5 0 11-10.61-10.603c4.945-4.948 11.522-7.673 18.518-7.673 6.997 0 13.573 2.725 18.519 7.673a7.5 7.5 0 01-5.304 12.801zM407.402 248.766c6.76 0 15.017 2.29 21.977 4.77 8.078 2.879 16.603-3.484 16.022-12.04-1.681-24.75-18.426-41.865-37.999-41.865-19.574 0-36.319 17.114-37.999 41.865-.581 8.556 7.944 14.919 16.022 12.04 6.961-2.48 15.217-4.77 21.977-4.77z"
          />
        </g>
        <g>
          <path
            fill="#a3e3ff"
            d="M459.665 331.583c0 14.452 11.716 26.168 26.167 26.168S512 346.035 512 331.583c0-30.399-22.553-49.698-33.723-47.428-13.349 2.713-18.612 25.063-18.612 47.428zM58.787 290.366c2.252 14.275-7.495 27.673-21.77 29.926-14.275 2.252-27.674-7.495-29.926-21.77-4.737-30.028 14.533-52.605 25.92-52.104 13.609.6 22.291 21.857 25.776 43.948z"
          />
        </g>
      </svg>
    </S.Wrapper>
  );
}

export default Dino;
