import styled, { css, keyframes } from 'styled-components';

const spin = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Wrapper = styled.div`
  ${({ theme }) => css`
    bottom: 0;
    height: 50px;
    left: 0;
    margin: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 50px;
    z-index: ${theme.layers.alwaysOnTop};

    &:before {
      background: radial-gradient(rgba(20, 20, 20, 0.25), rgba(0, 0, 0, 0.25));
      content: '';
      height: 100%;
      left: 0;
      position: fixed;
      top: 0;
      width: 100%;
    }
  `}
`;

export const Spinner = styled.div`
  ${({ theme }) => css`
    animation: ${spin} 0.7s linear infinite;
    border: 7px solid ${theme.colors.primary};
    border-left-color: ${theme.colors.secondary};
    border-radius: 50%;
    height: 100%;
  `}
`;
