import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${({ theme }) => css`
    height: 100%;
    width: 100%;

    .primary {
      fill: ${theme.colors.primary};
    }

    .secondary {
      fill: ${theme.colors.secondary};
    }
  `}
`;
