import styled, { css } from 'styled-components';

export const ErrorModalWrapper = styled.div`
  ${({ theme }) => css`
    background-color: rgba(200, 200, 200, 0.7);
    bottom: 0;
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    transition: all 0.3s;
    z-index: ${theme.layers.modal};
  `}
`;

export const ErrorModalContent = styled.div`
  ${({ theme }) => css`
    background: ${theme.colors.white};
    border-radius: ${theme.spacings.xxxsmall};
    display: flex;
    flex-flow: column;
    left: 50%;
    padding: 2em;
    position: absolute;
    top: 25%;
    transform: translate(-50%, -50%);
    width: 400px;
  `}
`;
