import React, { useState } from 'react';
import { useHistory } from 'react-router';

import { language } from './list';

export const KEY_I18N_LOCAL_STORAGE = 'i18nextLng';

import * as S from './styles';

const SelectLocale = () => {
  const history = useHistory();
  const [locale] = useState(localStorage.getItem(KEY_I18N_LOCAL_STORAGE));

  const handlerLocaleChange = (event) => {
    const { value } = event.target;
    localStorage.setItem(KEY_I18N_LOCAL_STORAGE, value);
    history.go();
  };

  return (
    <S.Wrapper onChange={handlerLocaleChange} value={locale}>
      {language.map((locale) => (
        <option key={locale.lng} value={locale.lng}>
          {locale.icon ?? locale.label}
        </option>
      ))}
    </S.Wrapper>
  );
};

export default SelectLocale;
