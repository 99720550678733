import React from 'react';

import ErrorModal from 'components/ErrorModal';
import { useAuth } from 'contexts/auth';
import { useErrorStore } from 'store';

function ErrorModalRoot() {
  const { signOut } = useAuth();
  const error = useErrorStore();

  const handleClose = () => {
    if (error.shouldRedirect) signOut();
    error.removeAll();
  };

  if (!error.messages.length) {
    return null;
  }

  return <ErrorModal errors={error.messages} onClose={handleClose} />;
}

export default ErrorModalRoot;
