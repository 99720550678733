import styled from 'styled-components';

export const Wrapper = styled.select`
  position: relative;
  width: 46px;
  cursor: pointer;

  border: none;
  outline: none;
  background: transparent;
`;
