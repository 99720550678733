export default {
  grid: {
    container: '1300px',
    gutter: '32px',
  },
  border: {
    radius: '10px',
    xsmall: '2px',
    small: '4px',
    medium: '8px',
    large: '12px',
    xlarge: '16px',
    xxlarge: '22px',
    xxxlarge: '28px',
  },
  font: {
    family: {
      primary:
        "Rajdhani, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
      secondary:
        "Lato, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    },
    light: 400,
    normal: 500,
    bold: 700,
    extraBold: 900,
    sizes: {
      xsmall: '12px',
      small: '14px',
      medium: '16px',
      large: '18px',
      xlarge: '24px',
      xxlarge: '28px',
      xxxlarge: '36px',
    },
  },
  colors: {
    primary: '#40bed3',
    darkPrimary: '#44a4ac',
    secondary: '#ffcc4e',
    darkSecondary: '#e7b133',
    mainBg: '#edeff1',
    white: '#ffffff',
    whiteTransparent: 'rgb(255 255 255 / 50%)',
    black: '#374547',
    gray: '#979797',
    lightGray: '#d8d8d8',
    error: '#e74c3c',
    success: '#4caf50',
  },
  spacings: {
    xxxsmall: '4px',
    xxsmall: '8px',
    xsmall: '16px',
    small: '24px',
    medium: '32px',
    large: '40px',
    xlarge: '48px',
    xxlarge: '56px',
  },
  layers: {
    base: 10,
    menu: 20,
    overlay: 30,
    modal: 1060,
    alwaysOnTop: 1070,
  },
  transitions: {
    duration: {
      short: '250ms',
    },
  },
};
