import styled, { css } from 'styled-components';
import media from 'styled-media-query';

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  height: 100vh;
`;

export const DinoWrapper = styled.div`
  height: auto;
  margin: auto;
  width: 50%;

  ${media.greaterThan('medium')`
    width: 25%;
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    align-items: center;
    background: ${theme.colors.primary};
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;
    padding: ${theme.spacings.medium};
    width: 100%;
  `}
`;

export const Text = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colors.white};
    font-size: ${theme.font.sizes.xlarge};
    line-height: 2rem;
    max-width: 992px;
    text-align: center;
  `}
`;

export const Link = styled.a`
  ${({ theme }) => css`
    color: ${theme.colors.secondary};
    font-size: ${theme.font.sizes.xlarge};
    font-weight: ${theme.font.bold};
    transition: ${theme.transitions.duration.short};

    &:hover {
      color: ${theme.colors.darkSecondary};
      text-decoration: none;
    }
  `}
`;
