import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import { useAuth } from 'contexts/auth';

import * as S from './styles';

const ErrorModal = ({ errors, onClose }) => {
  const { t } = useTranslation();
  const { signOut } = useAuth();
  const [modalErrors, setModalErrors] = useState([]);

  useEffect(() => {
    setModalErrors(
      errors.reduce((acc, errs) => {
        return acc.concat(t(errs[1] || errs[0], errs[2]?.interpolation));
      }, []),
    );
  }, [errors, t]);

  useEffect(() => {
    errors.forEach((err) => {
      if (err[1] === 'THE_TOKEN_SIGNATURE_HAS_EXPIRED') signOut();
    });
  }, [errors, signOut]);

  return (
    <S.ErrorModalWrapper>
      <S.ErrorModalContent>
        <ul>
          {modalErrors.map((err) => (
            <li key={err}>{err}</li>
          ))}
        </ul>
        <Button tabIndex={0} size="small" onClick={onClose}>
          {t('close')}
        </Button>
      </S.ErrorModalContent>
    </S.ErrorModalWrapper>
  );
};

ErrorModal.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorModal;
