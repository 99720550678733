import React, { lazy, Suspense } from 'react';
import { Route as RouteBase, Switch, Redirect, useLocation } from 'react-router-dom';

import { useAuth } from 'contexts/auth';
import FullPageSpinner from 'components/FullPageSpinner';

const Login = lazy(() => import('pages/Login'));
const SignUp = lazy(() => import('pages/SignUp'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const ResetPassword = lazy(() => import('pages/ResetPassword'));
const Dashboard = lazy(() => import('pages/Dashboard'));
const FunnelState = lazy(() => import('pages/FunnelState'));

const pathnames = ['/.well-known/microsoft-identity-association.json'];

function Route({ component: Component, isPrivate = false, ...props }) {
  const { pathname } = useLocation();
  const { signed } = useAuth();

  if (pathnames.includes(pathname)) {
    return <Redirect to={pathname} />;
  }

  if (!signed && isPrivate) {
    return <Redirect to="/entrar" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/" />;
  }

  return <RouteBase {...props} component={Component} />;
}

function Routes() {
  return (
    <Suspense fallback={<FullPageSpinner />}>
      <Switch>
        <Route path="/entrar" component={Login} />
        <Route path="/cadastrar" component={SignUp} />
        <Route path="/esqueci-minha-senha" component={ForgotPassword} />
        <Route path="/redefinir-senha/:id/:token" component={ResetPassword} />
        <Route isPrivate path="/dashboard/:id" component={FunnelState} />
        <Route isPrivate path="/" component={Dashboard} />
      </Switch>
    </Suspense>
  );
}

export default Routes;
