import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import cookies from 'js-cookie';
import { useApolloClient } from '@apollo/client';
import mixpanel from 'mixpanel-browser';
import jwt from 'jsonwebtoken';

import FullPageSpinner from 'components/FullPageSpinner';
import api from 'services/api';

import { KEY_I18N_LOCAL_STORAGE } from 'components/SelectLocale/SelectLocale';
const VALUE_I18N_LOCAL_STORAGE = localStorage.getItem(KEY_I18N_LOCAL_STORAGE);

const AuthContext = createContext();

function AuthProvider(props) {
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState('idle');

  const client = useApolloClient();

  const isPending = status === 'idle' || status === 'pending';

  useEffect(() => {
    const storagedUser = cookies.get('profile');
    const storagedToken = cookies.get('token');

    const decode = jwt.decode(storagedToken);

    if (storagedUser && storagedToken) {
      setUser({ ...JSON.parse(storagedUser), id: decode.id, permissions: decode.permissions });
      setStatus('resolved');
    } else {
      setStatus('rejected');
    }
  }, []);

  const signIn = async ({ token, profile }) => {
    cookies.set('token', token);

    const { data } = await api.get('/profile-picture');

    const decode = jwt.decode(token);
    const newProfile = { ...profile, picture: data.picture, id: decode.id, permissions: decode.permissions };

    setUser(newProfile);

    cookies.set('profile', JSON.stringify(newProfile));

    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.identify(`${user.company}:${user.name}`);

      mixpanel.people.set({
        $company: user.company,
        name: user.name,
      });
    }
  };

  const signOut = () => {
    cookies.remove('token');
    cookies.remove('profile');
    localStorage.clear();
    client.clearStore();
    localStorage.setItem(KEY_I18N_LOCAL_STORAGE, VALUE_I18N_LOCAL_STORAGE);
    setUser(null);
  };

  const onUpdate = useCallback(
    (profile) => {
      const storagedToken = cookies.get('token');
      const decode = jwt.decode(storagedToken);

      const newProfile = { ...user, ...profile, id: decode.id, permissions: decode.permissions };
      cookies.set('profile', JSON.stringify(newProfile));

      setUser(newProfile);
    },
    [user],
  );

  if (isPending) {
    return <FullPageSpinner />;
  }

  return <AuthContext.Provider value={{ signed: !!user, user, signIn, signOut, onUpdate }} {...props} />;
}

function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('Cannot use `useAuth` outside of a AuthProvider');
  }
  return context;
}

export { AuthProvider, useAuth };
