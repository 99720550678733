import styled, { css } from 'styled-components';

const toastTypeVariations = {
  info: (theme) => css`
    background: ${theme.colors.primary};
  `,
  success: (theme) => css`
    background: ${theme.colors.success};
  `,
  error: (theme) => css`
    background: ${theme.colors.errors};
  `,
};

export const Wrapper = styled.div`
  ${({ theme, type }) => css`
    align-items: center;
    border-radius: 5px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
    color: ${theme.colors.white};
    display: flex;
    padding: ${theme.spacings.xsmall};
    width: 360px;

    & + div {
      margin-top: ${theme.spacings.xxsmall};
    }

    > svg {
      margin-right: ${theme.spacings.xxsmall};
      height: 24px;
      width: 24px;
    }

    ${toastTypeVariations[type || 'info'](theme)}
  `}
`;

export const Content = styled.div`
  flex: 1;
`;

export const Title = styled.span`
  ${({ theme }) => css`
    font-weight: ${theme.font.bold};
  `}
`;
