import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import i18nextConfig from 'configs/i18next';
import { resources } from './languages';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ...i18nextConfig,
    resources,
  });

export default i18n;
