import styled, { css } from 'styled-components';
import { shade } from 'polished';

const buttonModifiers = {
  primary: {
    solid: (theme) => css`
      background: ${theme.colors.primary};
      color: ${theme.colors.white};

      &:hover {
        color: ${theme.colors.white};
        background: ${theme.colors.darkPrimary};
      }
    `,
    outline: (theme) => css`
      &:hover {
        color: ${theme.colors.black};
        background: ${theme.colors.lightGray};
      }
    `,
  },
  secondary: {
    solid: (theme) => css`
      background: ${theme.colors.secondary};
      color: ${theme.colors.black};

      &:hover {
        background: ${theme.colors.darkSecondary};
      }
    `,
    outline: () => css``,
  },
  white: {
    solid: (theme) => css`
      background: ${theme.colors.white};
      color: ${theme.colors.black};

      &:hover {
        background: ${theme.colors.lightGray};
      }
    `,
    outline: () => css``,
  },
  error: {
    solid: (theme) => css`
      background: ${theme.colors.error};
      color: ${theme.colors.black};

      &:hover {
        background: ${shade(0.2, theme.colors.error)};
      }
    `,
    outline: () => css``,
  },
  default: {
    solid: () => css``,
    outline: (theme) => css`
      border: 3px solid ${theme.colors.white};
      background: transparent;
      color: ${theme.colors.white};

      &:hover {
        color: ${theme.colors.mainBg};
        border-color: ${theme.colors.mainBg};
      }
    `,
  },
  xsmall: (theme) => css`
    height: 32px;
    font-size: ${theme.font.sizes.large};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xxsmall};
  `,
  small: (theme) => css`
    height: 40px;
    font-size: ${theme.font.sizes.large};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xsmall};
  `,
  medium: (theme) => css`
    height: 60px;
    font-size: ${theme.font.sizes.large};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.medium};
  `,
  large: (theme) => css`
    height: 70px;
    font-size: ${theme.font.sizes.xlarge};
    padding: ${theme.spacings.xxsmall} ${theme.spacings.xlarge};
  `,
  fullWidth: () => css`
    width: 100%;
  `,
  upper: () => css`
    text-transform: uppercase;
  `,
  normal: () => css``,
};

export const Wrapper = styled.button.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})`
  ${({ theme, color, font, size, fullWidth, text, variant }) => css`
    align-items: center;
    border: 0;
    border-radius: ${theme.border.radius};
    cursor: pointer;
    display: flex;
    font-family: ${theme.font.family[font]};
    font-weight: ${theme.font.extraBold};
    justify-content: center;
    text-align: center;
    text-decoration: none;
    transition: 250ms;
    will-change: background;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      box-shadow: 0 0 0 2px ${theme.colors.darkPrimary};
      outline: none;
    }

    &:disabled {
      background: rgba(0, 0, 0, 0.12);
      color: rgba(0, 0, 0, 0.26);
      cursor: not-allowed;

      &:hover {
        background: rgba(0, 0, 0, 0.12);
        color: rgba(0, 0, 0, 0.26);
      }
    }

    ${!!color && buttonModifiers[color][variant](theme)}
    ${!!size && buttonModifiers[size](theme)}
    ${!!fullWidth && buttonModifiers.fullWidth}
    ${!!text && buttonModifiers[text]}
  `}
`;
