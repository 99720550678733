import React from 'react';
import PropTypes from 'prop-types';
import Toast from 'components/Toast';

import * as S from './styles';

function ToastContainer({ messages }) {
  return (
    <S.Wrapper isOpen={!!messages.length}>
      {messages.map((message) => (
        <Toast key={message.id} message={message} />
      ))}
    </S.Wrapper>
  );
}

ToastContainer.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default ToastContainer;
