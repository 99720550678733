import axios from 'axios';
import cookie from 'js-cookie';

const api = axios.create({ baseURL: process.env.REACT_APP_API_URL });

api.interceptors.request.use(async (config) => {
  const token = cookie.get('token');

  if (token) {
    const { headers } = config;

    return {
      ...config,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    };
  }
  return config;
});

export default api;
