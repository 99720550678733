import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import cookie from 'js-cookie';

import { useErrorStore } from 'store';

const httpLink = createHttpLink({ uri: `${process.env.REACT_APP_API_URL}/graphql` });

const authLink = setContext((_, { headers }) => {
  const token = cookie.get('token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    const doesIncludeAuthenticationError = graphQLErrors.some((err) => err.extensions?.code === 'UNAUTHENTICATED');
    if (doesIncludeAuthenticationError) {
      useErrorStore.setState({ messages: [['sessionExpired']], shouldRedirect: true });
      return;
    }

    if (graphQLErrors.some((err) => err.extensions?.code === 'INTERNAL_SERVER_ERROR')) {
      useErrorStore.setState({ messages: [['opsThereWasAProblem']] });
      return;
    }

    const errors = graphQLErrors.reduce((errors, err) => {
      return [...errors, [err.message, err.extensions?.code, { interpolation: err.extensions }]];
    }, []);
    useErrorStore.setState({ messages: errors });
    return;
  }

  if (networkError) useErrorStore.setState({ messages: [['opsThereWasAProblem']] });
});

const client = new ApolloClient({
  link: errorLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
});

export default client;
