import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  ${({ theme }) => css`
    body {
      background: ${theme.colors.mainBg};
      box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.12);
      color: ${theme.colors.black};
      font-family: ${theme.font.family.secondary};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  `}
  
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
`;

export default GlobalStyle;
