import React from 'react';
import PropTypes from 'prop-types';

import * as S from './styles';

function IconButton({ children, radius, color, size, type, variant, ...props }) {
  return (
    <S.Wrapper color={color} radius={radius} size={size} type={type} variant={variant} {...props}>
      {children}
    </S.Wrapper>
  );
}

IconButton.defaultProps = {
  color: 'primary',
  size: 'medium',
  type: 'button',
  variant: 'ghost',
};

IconButton.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary', 'gray', 'error', 'white', 'black', 'success']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  type: PropTypes.oneOf(['button', 'submit']),
  variant: PropTypes.oneOf(['solid', 'ghost']),
  radius: PropTypes.oneOf(['none', 'small', 'medium', 'large', 'full']),
};

export default IconButton;
