import React, { createContext, useCallback, useContext, useState } from 'react';

import ToastContainer from 'components/ToastContainer';

const ToastContext = createContext();

function ToastProvider({ children }) {
  const [messages, setMessages] = useState([]);

  const addMessage = useCallback(({ type, title }) => {
    const id = Math.random().toString(36).substring(7);

    const message = {
      id,
      type,
      title,
    };

    setMessages((state) => [...state, message]);
  }, []);

  const removeMessage = useCallback((id) => {
    setMessages((state) => state.filter((message) => message.id !== id));
  }, []);

  return (
    <ToastContext.Provider
      value={{
        addMessage,
        removeMessage,
      }}
    >
      {children}
      <ToastContainer messages={messages} />
    </ToastContext.Provider>
  );
}

function useToast() {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { ToastProvider, useToast };
