import * as Sentry from '@sentry/react';

const isProduction = process.env.NODE_ENV === 'production';

const config = {
  dsn: 'https://53ebf18a88444ad08cd24c389c7b8e84@o383398.ingest.sentry.io/5368518',
  environment: process.env.NODE_ENV,
  enabled: isProduction,
  integrations: [
    new Sentry.Integrations.GlobalHandlers({ onerror: false, onunhandledrejection: false }),
    new Sentry.Integrations.TryCatch({ XMLHttpRequest: false }),
  ],
};

export default config;
